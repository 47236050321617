import './scss/index.scss';

// components
import './components/index';

// layout
import './layouts/main/main';

// pages
import './pages/main/main';
import './pages/text-page/text-page';
import './pages/company/company';
import './pages/page-news/page-news';
import './pages/news/news';
import './pages/contacts/contacts';
import './pages/career/career';
import './pages/container-transporting/container-transporting';
import './pages/equipment-rent/equipment-rent';
import './pages/rail-transportation/rail-transportation';
import './pages/project-transportation/project-transportation';
import './pages/export-transportation/export-transportation';
import './pages/autotransportation/autotransportation';
import './pages/customs-clearance/customs-clearance';
import './pages/not-found/not-found';
import './pages/search/search';

function requireAll(r) {
    r.keys().forEach(r);
};

requireAll(require.context('./assets/icons/', true, /\.svg$/));