import './header.scss';
import './mobile-menu/mobile-menu';

$(function() {
    const $headerSearch = $('.js-header-search');
    const $headerLoc = $('.js-header-location');
    const $headerLocDropdown = $('.js-location-dropdown');
    const $headerPhone = $('.js-header-phone');
    const $headerLang = $('.js-header-lang');

    $('.js-header-nav-item').each(function() {
        const $item = $(this);
        const $dropdown = $item.find('.js-header-dropdown');

        $item.hover(() => {
            $item.addClass('active');
            if ($dropdown.length) {
                $dropdown.removeClass('hidden');
                $dropdown.stop(true, true).fadeIn(150);
            }
        }, () => {
            $item.removeClass('active');
            $dropdown.fadeOut(150);
        });
    });

    $('.js-menu-toggle').on('click', function() {
        const $menu = $('#mobile-menu');
        $menu.toggleClass('is-open');
        $('.js-menu-toggle').toggleClass('open');

        if ($menu.hasClass('is-open')) {
            $('body').addClass('lock');
        } else {
            $('body').removeClass('lock');
        }
    });

    $('.js-toggle-search').on('click', function() {
        $headerSearch.toggleClass('open');
        $headerLoc.toggleClass('hide');
        $headerPhone.toggleClass('hide');
        $headerLang.toggleClass('hide');

        const mouseupHandler = function(e) {
            if (!$headerSearch.is(e.target) && $headerSearch.has(e.target).length === 0) {
                $headerSearch.removeClass('open');
                $headerLoc.removeClass('hide');
                $headerPhone.removeClass('hide');
                $headerLang.removeClass('hide');
                $(document).off('mouseup', mouseupHandler);
            }
        };

        $(document).on('mouseup', mouseupHandler);
    });

    $('.js-mobile-menu-item').each(function() {
        const $item = $(this);
        const $dropdown = $item.find('.js-mobile-menu-dropdown');

        $item.on('click', function() {
            if ($dropdown.length) {
                if ($item.hasClass('active')) {
                    $dropdown.slideUp();
                    $item.removeClass('active');
                } else {
                    $dropdown.slideDown();
                    $item.addClass('active');
                }
            }
        });
    });

    $('.js-toggle-location').on('click', function() {
        $headerLocDropdown.toggleClass('open');

        const mouseupHandler = function(e) {
            if (!$headerLocDropdown.is(e.target) && $headerLocDropdown.has(e.target).length === 0) {
                $headerLocDropdown.removeClass('open');
                $(document).off('mouseup', mouseupHandler);
            }
        };

        $(document).on('mouseup', mouseupHandler);
    });
});