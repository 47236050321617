import './textarea.scss';

$.fn.libTextarea = function(options, value) {
    if (typeof options === 'string') {
        const method = options;

        $(this).each(function() {
            const libTextarea = $(this).prop('libTextarea');
            libTextarea[method](value);
        });
    } else {
        $(this).each(function() {
            const $element = $(this);
            const $hint = $(this).siblings('.js-textarea-hint');

            const libTextarea = {
                showError: () => {
                    $(this).addClass('textarea-error');
                },
                removeError: () => {
                    $(this).removeClass('textarea-error');
                },
                showHint: () => {
                    $hint.fadeIn(200);
                    $hint.removeClass('hidden');
                },
                hideHint: () => {
                    $hint.fadeOut(200);
                },
            };

            $element.on('input change', function() {
                libTextarea.removeError();
                libTextarea.hideHint();
            });

            $element.prop('libTextarea', libTextarea);
        });
    }
};

$('.js-textarea').libTextarea();
