import './input.scss';

$.fn.libInput = function(options, value) {
    if (typeof options === 'string') {
        const method = options;

        $(this).each(function() {
            const libInput = $(this).prop('libInput');
            libInput[method](value);
        });
    } else {
        $(this).each(function() {
            const $element = $(this);
            const $hint = $(this).siblings('.js-input-hint');

            const libInput = {
                showError: () => {
                    $(this).addClass('input-error');
                },
                removeError: () => {
                    $(this).removeClass('input-error');
                },
                showHint: () => {
                    $hint.fadeIn(200);
                    $hint.removeClass('hidden');
                },
                hideHint: () => {
                    $hint.fadeOut(200);
                },
            };

            $element.on('input change', function() {
                libInput.removeError();
                libInput.hideHint();
            });

            $element.prop('libInput', libInput);
        });
    }
};

$('.js-input').libInput();
