import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import './full-width-slider.scss';


export const fullWidthSlider = ({
    element = '.js-full-width-slider',
    context,
} = {}) => {
    $(element, context).each(function() {
        const $slider = $(this);
        const swiper = new Swiper($slider.get(0), {
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: "auto",
            spaceBetween: 20,
            breakpoints: {
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true,
                    speed: 1000,
                },
            }
        });
    });
};

fullWidthSlider();