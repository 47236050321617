import './job-form.scss';
import Inputmask from 'inputmask';
import { validateEmail, validatePhone, validateName } from '../../../utils/validation';

export const requestForm = ({
    element = '.js-job-form',
    context,
} = {}) => {
    $(element, context).each(function() {
        const $form = $(this);
        const $inputs = $form.find('.js-input');
        const $message = $form.find('.js-textarea');
        const $fileInput = $form.find('.js-upload-input');
        const $submitBtn = $form.find('.js-form-submit-btn');
        let isPending = false;
        $submitBtn.removeAttr('type').attr('type', 'submit');

        const inputMask = new Inputmask({ 
            mask: ['+7 (999)999-99-99', '8 (999)999-99-99'],
            showMaskOnHover: false
        });
        inputMask.mask($form.find('.js-input[name="phone"]'));

        // функция валидации текстового поля
        function validateTextarea(textarea) {
            const value = textarea.val();
            if (value.length > 5) {
                return true;
            } else {
                textarea.libTextarea('showError');
                textarea.libTextarea('showHint');
                return false;
            }
        };

        // функция валидации инпутов
        function validateInputs(inputs) {
            const invalidInputs = [];

            inputs.each(function() {
                const $input = $(this);
                const value = $input.val();
                const name = $input.attr('name');

                if (name === 'name') {
                    if (!validateName(value)) {
                        invalidInputs.push($input);
                    }
                }

                if (name === 'phone') {
                    if (!validatePhone(value)) {
                        invalidInputs.push($input);
                    }
                }

                if (name === 'email') {
                    if (!validateEmail(value)) {
                        invalidInputs.push($input);
                    }
                }
            });
    
            if (invalidInputs.length) {
                invalidInputs.forEach((input) => {
                    input.libInput('showError');
                    input.libInput('showHint');
                });
                return false;
            } else {
                return true;
            }
        };

        // функция валидации инпутов
        function validateForm(inputs, message) {
            const validateResult = [validateTextarea(message), validateInputs(inputs)];
            return validateResult.indexOf(false) < 0;
        };

        $form.on('submit', (e) => {
            e.preventDefault();
            if (isPending) return;

            const inputsData = $form.serializeArray().reduce((acc, { name, value }) => {
                acc[name] = value;
                return acc;
            }, {});

            const file = {
                file: $fileInput[0]?.files || [],
            };

            const formData = {...file, ...inputsData};

            if (validateForm($inputs, $message)) {
                isPending = true;
                $submitBtn.attr('disabled', true);
                $submitBtn.libButton('showLoader');

                window.PUBLIC_INTERFACE.sendJobForm({
                    data: formData
                }, function() {
                    isPending = false;
                    
                    $submitBtn.attr('disabled', false);
                    $submitBtn.libButton('hideLoader');

                    // добавляем класс success для контейнера формы
                    const $formContainer = $form.closest('[data-form-container');
                    const formHeight = $formContainer.get(0).getBoundingClientRect().height;
                    $formContainer.css({'min-height': formHeight});
                    $formContainer.addClass('success');
                    $formContainer.find('[data-form-title]').text('Спасибо за заявку!');
                    $form.addClass('hidden');
                    $formContainer.find('.js-form-success').removeClass('hidden');
                }, function() {
                    isPending = false;
                    console.log('Ошибка');
                });
            }
        });
    });
}

requestForm();