import './company.scss';


$(function() {
    const windowWidth = $(window).width();

    if ($('#scrollable-years').length) {
        const scrollContainer = $('.js-scrollable').get(0);

        scrollContainer.addEventListener('wheel', (e) => {
            e.preventDefault(e);
            scrollContainer.scrollLeft += Math.abs(e.deltaY) > Math.abs(e.deltaX) ? e.deltaY : e.deltaX;
        });
    };
});