import './button.scss';

$.fn.libButton = function(options, value) {
    if (typeof options === 'string') {
        const method = options;

        $(this).each(function() {
            const libButton = $(this).prop('libButton');
            libButton[method](value);
        });
    } else {
        $(this).each(function() {
            const $element = $(this);

            const libButton = {
                showLoader: () => {
                    $(this).addClass('loading');
                },
                hideLoader: () => {
                    $(this).removeClass('loading');
                },
            };

            $element.prop('libButton', libButton);
        });
    }
};

$('.js-button').libButton();