import Plyr from 'plyr';
import './video-block.scss';


$('.js-video-block').each(function() {
    const provider = $(this).data('video-provider');
    const url = $(this).data('video-url');
    const type = $(this).data('video-type');
    const $videoContainer = $(this).find('.video-block__container');

    switch(provider) {
        case "youtube" || "vimeo":
            $videoContainer.append(
                `<div class="js-video">
                    <iframe
                        src=${url}
                        allowfullscreen
                        allowtransparency
                        allow="autoplay"
                    ></iframe>
                </div>`);
            break;
        default:
            const videoType = type ? `type="video/${type}"` : '';
            $videoContainer.append(
                `<video class="js-video" playsinline controls>
                    <source src=${url} ${videoType}/>
                </video>`);
    }

    const player = new Plyr(`.js-video`);
});