import './autotransportation.scss';


$(function() {
    const $mapContainer = $('.service-page__map-container');

    $('.highlighted-area-container').each(function() {
        const $areasContainer = $(this);
        const areaName = $areasContainer.data('area-name');
        const highlighted = $areasContainer.find('.highlighted-area');
        const $baloon = $('#map-baloon');
        const $baloonText = $baloon.find('.js-baloon-text');
        
        $areasContainer.hover(function() {
            const containerRect = $mapContainer.get(0).getBoundingClientRect();
            const baloonRect = $baloon.get(0).getBoundingClientRect();
            const areaRect = $areasContainer.get(0).getBoundingClientRect();

            highlighted.addClass('active');
            $baloon.addClass('active');

            const top = areaRect.top - containerRect.top + areaRect.height / 2 - baloonRect.height;
            const left = areaRect.left - containerRect.left + areaRect.width / 2;

            $baloon.css({ top, left });
            $baloonText.text(areaName);
        }, function() {
            highlighted.removeClass('active');
            $baloon.removeClass('active');
        });
    });
});
