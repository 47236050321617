import Swiper, { Navigation } from 'swiper';
import fancybox from '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import 'swiper/css';
import './route-map/route-map';
import './main-vacancy/main-vacancy';
import './main-news-item/main-news-item';
import './main.scss';


$(function() {
    const $mainService = $('.js-service-main');
    const $welcomeSvgGroup = $('#welcome-svg-group');
    const $animatedElements = $welcomeSvgGroup.find('.animated-element');

    const objects = {
        lorry: {
            delay: 1000,
            direction: 'left',
        },
        train: {
            delay: 7000,
            direction: 'left',
        },
        ship: {
            delay: 3000,
            direction: 'right',
            
        },
        plane: {
            delay: 0,
            direction: 'right',
            yOffset: `-220px`,
        },
    };

    $animatedElements.each(function(i) {
        const $el = $(this);
        const containerRect = $welcomeSvgGroup.get(0).getBoundingClientRect();
        const elRect = $el.get(0).getBoundingClientRect();
        const elOffsetRight = (containerRect.width - (elRect.left - containerRect.left)) / containerRect.width * 100;
        const elOffsetLeft = (elRect.left - containerRect.left + elRect.width) / containerRect.width * 100;

        const id = $el.attr('id');
        const object = objects[id];
        const isLeftToRight = object.direction === 'right';

        const from = isLeftToRight ? -elOffsetLeft : elOffsetRight;
        const to = isLeftToRight ? elOffsetRight : -elOffsetLeft;
        const animationName = `animation-${i}`;
        const translateY = object.yOffset ? `translateY(${object.yOffset})` : '';
        const animationKeyFrames = `
            <style>
            @keyframes ${animationName} {
                from {
                    transform: translateX(${from}%) ${translateY};
                }
                to {
                    transform: translateX(${to}%) ${translateY};
                }
            }
            </style>
        `;
        $(document.body).append(animationKeyFrames);

        $el.css({
            opacity: 1,
            transform: `translateX(${from}%)`,
        });

        setTimeout(() => {
            $el.css({
                animation: `${animationName} ${object.duration || 10}s linear infinite`,
            })
        }, object.delay);
    });

    $('.js-single-image').each(function() {
        $(this).fancybox();
    });

    $('.js-service').hover(function() {
        const title = $(this).attr('data-service-title');
        const text = $(this).attr('data-service-text');
        const img = $(this).find('.service-item__img img');
        $mainService.find('.js-service-title').text(title);
        $mainService.find('.js-service-text').text(text);
        $mainService.find('.js-service-img').html(img.clone());
    });
    
    $('.js-certificates-slider').each(function() {
        const $slider = $(this);
        const swiper = new Swiper($slider.get(0), {
            modules: [Navigation],
            slidesPerView: 'auto',
            spaceBetween: 20,
            navigation: {
                nextEl: $slider.find('.swiper-button-next').get(0),
                prevEl: $slider.find('.swiper-button-prev').get(0),
            },
            breakpoints: {
                1280: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                    freeMode: true,
                },
            }
        });
    });

    $('.js-news-slider').each(function() {
        const $slider = $(this);
        const swiper = new Swiper($slider.get(0), {
            modules: [Navigation],
            slidesPerView: 'auto',
            loop: true,
            spaceBetween: 20,
            freeMode: true,
            navigation: {
                nextEl: $slider.find('.swiper-button-next').get(0),
                prevEl: $slider.find('.swiper-button-prev').get(0),
            },
            breakpoints: {
                1280: {
                    slidesPerView: 3,
                    allowTouchMove: false,
                    effect: 'fade',
                    centeredSlides: true,
                    spaceBetween: 30,
                    speed: 600,
                },
            }
        });
    });

    if ($('#scrollable-counts').length) {
        if ($('#scrollable-counts').hasClass('main-page__counts--empty')) return;

        const $positionDetecter = $('.js-position-detecter').get(0);
        const $scrollable = $('.js-scrollable').get(0);
        let scrollableRect = $scrollable.getBoundingClientRect();

        $(window).on('resize', function() {
            scrollableRect = $scrollable.getBoundingClientRect();
        });

        $(window).on('scroll', function() {
            const rect = $positionDetecter.getBoundingClientRect();
            const height = 5000 - window.innerHeight;
            const percent = rect.top < 0 ? Math.min(1, Math.abs(rect.top) / height) : 0;
            $scrollable.scrollLeft = Math.round(($scrollable.scrollWidth - scrollableRect.width) * percent);
        });
    }
});