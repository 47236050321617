import './modal.scss';

$(function () {
    $('.js-modal-toggle').on('click', function(e) {
        e.preventDefault();

        const dataFor = $(this).attr('data-for');
        const $modal = $(`#${dataFor}`);
        $modal.toggleClass('is-visible');

        if ($modal.hasClass('is-visible')) {
            $('body').addClass('lock');
        } else {
            $('body').removeClass('lock');
        }
    });
});