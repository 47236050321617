import Swiper, { Navigation } from 'swiper';
import fancybox from '@fancyapps/fancybox';
import './career.scss';
import './vacancy/vacancy';
import './job-form/job-form';

$(function() {
    $('[data-fancybox="grid-gallery-group"], [data-fancybox="slider-gallery-group"]').fancybox({
        buttons: [
            "zoom",
            "close",
        ],
        'speedIn': 600, 
        'speedOut': 200, 
        'overlayShow': false,
        loop: false,
    });

    $('.js-gallery-slider').each(function() {
        const $slider = $(this);
        const swiper = new Swiper($slider.get(0), {
            slidesPerView: 'auto',
            spaceBetween: 20,
            freeMode: true
        });
    });

    $('a.scrollto').click(function(e) {
        e.preventDefault();
        const anchorId = $(this).attr('href');
        const anchor = $(`${anchorId}`);
        $('body, html').animate({
            scrollTop: anchor.offset().top
        }, 250);
    });
});