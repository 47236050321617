import './main.scss';
import './order-form/order-form';

$(function() {
    const $buttonUp = $('#js-button-up');
    let isProcessing = false;
    let timeout;

    if (!$('#main-page').length) {
        $buttonUp.addClass('fixed');
    };

    $buttonUp.on('click', function() {
        if (isProcessing) return;
        isProcessing = true;

        $('body, html').animate({
            scrollTop: 0
        }, 200, function() {
            isProcessing = false;
        });
    });

    $(window).on('scroll', function() {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            if ($(this).scrollTop() > 300) {
                $buttonUp.removeClass('hidden');
                $buttonUp.fadeIn(150);
                
            } else {
                $buttonUp.fadeOut(150);
            }
        }, 100);
    });
});
