import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import './slider.scss';


export const slider = ({
    element = '.js-slider',
    context,
} = {}) => {
    $(element, context).each(function() {
        const $slider = $(this);
        const swiper = new Swiper($slider.get(0), {
            modules: [Navigation],
            loop: true,
            slidesPerView: 1,
            speed: 1000,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    });
};

slider();