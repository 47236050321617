import Vivus from 'vivus';
import Panzoom from '@panzoom/panzoom';
import { reverse as reverseSvgPath } from 'svg-path-reverse/reverse';
import './route-map.scss';
import { validateEmail, validatePhone, validateName } from '../../../utils/validation';


let animationCount = 0;
const createAnimation = (elem) => {
    if (elem._animationManager) {
        return elem._animationManager;
    }

    const cx = elem.getAttribute('cx');
    const cy = elem.getAttribute('cy');

    const name = `my-animation-${animationCount++}`;
    const translate = `translate(${cx}px, ${cy}px)`;
    const css = `
        @keyframes ${name} {
            0% {
                transform: ${translate} scale(1);
            }
            50% {
                transform: ${translate} scale(1.2);
            }
            100% {
                transform: ${translate} scale(1);
            }
        }

        .cls-${name} {
            transition: transform 1s linear;
            transform: ${translate} scale(1);
            animation: ${name} 1s linear infinite;
        }
    `;

    const style = document.createElement('style');
    style.innerText = css;
    document.body.appendChild(style);

    const $elem = $(elem);
    let isPlay = false;
    elem._animationManager = {
        play: () => {
            if (isPlay) return;
            isPlay = true;
            $elem.attr('cx', 0);
            $elem.attr('cy', 0);
            $elem.addClass(`cls-${name}`);
        },
        pause: () => {
            if (!isPlay) return;
            isPlay = false;
            $elem.on('animationiteration webkitAnimationIteration', function handler() {            
                $elem.attr('cx', cx);
                $elem.attr('cy', cy);
                $elem.removeClass(`cls-${name}`);
            
                $elem.off('animationiteration webkitAnimationIteration', handler);
            });
        },
    };

    return elem._animationManager;
};

const COUNTRY_RUSSIA_ID = 40;
const BY_ROUTE_TYPE = {
    1: 'train',
    2: 'sea',
};

const getLang = () => {
    const matched = location.pathname.match(/^\/(en)(\/.*|$)/);
    if (!matched) return 'ru';
    return matched[1];
};

// const getLang = () => 'ru';

const getTitle = (item, propName = 'title') => {
    const lang = getLang();
    return item?.[`${propName}_${lang}`] || '';
};

const findAvailablePointsFrom = (routes, fromId) => {
    return Object.keys(routes[fromId] || {}).reduce((acc, key) => {
        acc[key] = true;
        return acc;
    }, {});
};

$(function() {
    const $routesForm = $('#routes-form');
    const $routesFormSubmitBtn = $routesForm.find('.js-form-submit-btn');
    
    const $routesRequestForm = $('#route-request-form');
    const $routeResultsList = $('#route-results-list');
    const $routemapErrorHint = $('#routemap-error-hint');
    
    const $selectFrom = $('#route-select-from');
    const $selectTo = $('#route-select-to');
    const $globe = $('#routemap-globe');

    const $panzoomElem = $('#panzoom-element');
    let panzoomMap;

    const animated = ['red-line-1', 'red-line-2', 'red-line-3'];
    let vivusManager;

    let isPending = false;

    let routeTypes;
    let routes;
    let backRoutes;
    let points;
    let countries;
    let globeAnimationTimeout;

    const routeData = {
        from: 0,
        to: 0,
    };

    $.get(`${__webpack_public_path__}data/routes.json`, function(data, status) {
        routes = data;
        backRoutes = Object.keys(routes).reduce((acc, fromId) => {
            Object.keys(routes[fromId]).forEach((toId) => {
                acc[toId] = acc[toId] || {};
                acc[toId][fromId] = routes[fromId][toId].map((route) => (
                    route.map(([id], i) => ([id, route[i + 1]?.[1] || null])).reverse()
                ));
            });
            return acc;
        }, {});

        $.get(`${__webpack_public_path__}data/cities.json`, function(data, status) {
            routeTypes = data.routeTypes;
            points = data.points;
            countries = data.countries;

            const options = Object.values(points).filter(({ isTransitPoint }) => (
                !isTransitPoint
            )).map((point) => {
                const country = countries[point['country_id']];
                return {
                    label: `${getTitle(point)}, ${getTitle(country)}`,
                    value: point['id'],
                    point,
                };
            });

            $('.js-routemap-select').each(function() {
                const $select = $(this);
                initRouteSelect($select, options);
            });
        });
    });

    $routesForm.on('submit', function(e) {
        e.preventDefault();
        
        if (isPending) return;
        $routesFormSubmitBtn.attr('disabled', true);
        clearRoutesList();

        if (validateRoutesData(routeData)) {
            isPending = true;
            clearTimeout(globeAnimationTimeout);

            vivusManager = vivusManager || initVivus();
            vivusManager.start();

            $routesFormSubmitBtn.libButton('showLoader');
            globeAnimationTimeout = setTimeout(() => {
                isPending = false;
                vivusManager.stop();
                if ($panzoomElem.length) initPanzoom($panzoomElem);
                renderSvg();
                $globe.addClass('hidden');
                $routesFormSubmitBtn.libButton('hideLoader');
                $routeResultsList.removeClass('hidden');
                $routesRequestForm.parents('[data-form-container]').removeClass('hidden');


                const findedRoutes = routes[routeData.from.point.id]?.[routeData.to.point.id] 
                    || backRoutes[routeData.from.point.id]?.[routeData.to.point.id]
                    || [];

                findedRoutes.forEach((route) => {
                    const routePath = route.map(([id, byRouteType], i) => {
                        const nextRoutePoint = route[i + 1];
                        const [nextPointId, nextByRouteType] = nextRoutePoint || [];
                        const point = points[id];
                        const nextRouteType = routeTypes[BY_ROUTE_TYPE[nextByRouteType]];
                        let nextRouteTypeTitle = nextByRouteType ? `(${getTitle(nextRouteType)})` : '';

                        // исключение для Москва - СПБ
                        const isMskSpb = (id) => id === 4 || id === 5;
                        if (isMskSpb(id) && isMskSpb(nextPointId)) {
                            nextRouteTypeTitle = '';
                        }
                            
                        return `<span>${getTitle(point)} ${nextRouteTypeTitle}</span>`;
                    });
                    $routeResultsList.append(`
                        <li class="routemap__routes-item">
                            ${routePath.join('')}
                        </li>
                    `);
                });
            }, 1500);
        }
    });

    $routesRequestForm.on('submit', function(e) {
        e.preventDefault();
        if (isPending) return;

        const $form = $(this);
        const $inputs = $form.find('.js-input');
        const $submitBtn = $form.find('.js-form-submit-btn');

        if (validateRoutesData(routeData)) {
            const inputsData = $(this).serializeArray().reduce((acc, { name, value }) => {
                acc[name] = value;
                return acc;
            }, {});
    
            const formData = {...routeData, ...inputsData};
            
            if (validateInputs($inputs)) {
                isPending = true;
                $submitBtn.attr('disabled', true);
                $submitBtn.libButton('showLoader');
    
                window.PUBLIC_INTERFACE.sendRouteRequestForm({
                    data: formData
                }, function() {
                    isPending = false;
                    $submitBtn.attr('disabled', false);
                    $submitBtn.libButton('hideLoader');
                    const $formContainer = $form.parents('[data-form-container]');
                    const formHeight = $formContainer.get(0).getBoundingClientRect().height;
                    $formContainer.css({'min-height': formHeight});
                    $formContainer.addClass('success');
                    $form.addClass('hidden');
                    $formContainer.find('.js-form-success').removeClass('hidden');
                }, function() {
                    isPending = false;
                    console.log('Ошибка');
                });
            }
        } else {
            console.log('Выберите маршрут');
        }
    });

    function validateRoutesData(routeData) {
        const invalid = Object.keys(routeData).filter((key) => {
            return routeData[key] === 0;
        });

        if (!invalid.length) {
            return true;
        } else {
            showErrorHint();
        }
    };

    function renderSvg() {
        const $routesContainer = $('#routes-container');
        $routesContainer.find('.route-item').remove();

        loadSvgRoute(routeData.from.point, routeData.to.point, (route, routeId, isReverseStack) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(route, 'image/svg+xml');
        
            $routesContainer.append(doc.documentElement);
            
            // Пересоздаем svg
            const html = $panzoomElem.html();
            $panzoomElem.html('');
            $panzoomElem.html(html);

            const fromData = routeData.from;
            const toData = routeData.to;
            let currentRoute = $panzoomElem.find(`#${routeId}`);

            const routeLines = currentRoute.find('.route-lines');
            const pointCircles = currentRoute.find('.route-point-circle');
           
            // анимация для поинтов
            function animateCircle(circle, status = true) {
                const elem = circle.get(0);
                const animation = createAnimation(elem);

                if (status) {
                    animation.play();
                } else {
                    animation.pause();
                }
            };

            pointCircles.each(function() {
                animateCircle($(this));
            });

            // отразить маршрут, если выбрана точка не Россия
            if (isReverseStack) {
                routeLines.each(function() {
                    const paths = $(this).find('path');

                    paths.each(function() {
                        const d = $(this).attr('d');
                        const reversed = reverseSvgPath(d);
                        $(this).attr('d', reversed);
                    });
                });
            }

            const baloon = currentRoute.find('.route-baloon-title');
            const routeWidth = currentRoute.get(0).getBoundingClientRect().width;
            const routeRight = currentRoute.get(0).getBoundingClientRect().right;

            panzoomMap.pan(`-${routeRight + routeWidth}`, 0);
            panzoomMap.zoom(0, { animate: true });
            // panzoomMap.zoomOut();

            // добавляем тайтлы
            baloon.each(function() {
                const $title = $(this);
                const titleId =  $title.data('title-id');
    
                $(this).text(getTitle(points[titleId]));

                if ($(this).hasClass('route-baloon-title-red')) {
                    const titleCoord = $title.get(0).getBoundingClientRect();
                    const titleTransitionX = $title.get(0).transform.baseVal.consolidate().matrix.e;
                    const titleTransitionY = $title.get(0).transform.baseVal.consolidate().matrix.f;

                    // создаем подложку для тайтла в красном балуне
                    const titleCover = $(document.createElementNS('http://www.w3.org/2000/svg', 'rect')).attr({
                        x: titleTransitionX - 20,
                        y: titleTransitionY - titleCoord.height - 5,
                        width: titleCoord.width + 40,
                        height: titleCoord.height + 15,
                        rx: 10,
                        ry: 10,
                        fill: '#DE2D1C',
                    });

                    const g = $(document.createElementNS("http://www.w3.org/2000/svg", "g")).attr({
                        class: 'baloon-title-group'
                    });

                    $title.after(g);
                    g.append(titleCover);
                    g.append($title);
                }
            });

            // Сортируем path в dom таким образом, чтобы их последовательность совпадала с порядком их отрисовки
            routeLines.each(function() {
                const $paths = $(this).find('path');

                const pathsData = [];
                $paths.each(function() {
                    pathsData.push({
                        startPoint: this.getPointAtLength(0),
                        endPoint: this.getPointAtLength(this.getTotalLength()),
                        path: this,
                    });
                });

                pathsData.sort((a, b) => {
                    // Расстояние между конечной точкой a и начальной точкой b
                    const d1 = Math.sqrt(
                        Math.pow(a.endPoint.x - b.startPoint.x, 2) +
                        Math.pow(a.endPoint.y - b.startPoint.y, 2)
                    );
                    // Расстоние между конечной точкой b и начальной точкой a
                    const d2 = Math.sqrt(
                        Math.pow(a.startPoint.x - b.endPoint.x, 2) +
                        Math.pow(a.startPoint.y - b.endPoint.y, 2)
                    );

                    // < 0 = a before b
                    return d1 - d2;
                });

                pathsData.forEach(({ path }) => {
                    path.parentNode.appendChild(path);
                });
            });

            setTimeout(() => {
                currentRoute.css({'opacity': '1', 'transition': 'all 0.3s linear'});
                routeLines.each(function() {
                    new Vivus($(this).get(0), {
                        type: 'oneByOne',
                        duration: 200,
                        // reverseStack: isReverseStack,
                        animTimingFunction: Vivus.EASE
                    }, () => {
                        pointCircles.each(function() {
                            animateCircle($(this), false);
                        });
                    });
                });
            }, 300);
        }, (e) => {
            console.error(e);
        });
    };

    function initRouteSelect($select, options) {
        const $notFound = $select.parents('.select-group').find('.select-group__not-found');

        $select.selectize({
            searchField: 'label',
            respect_word_boundaries: false,
            options: options,
            plugins: ['clear_button'],
            render: {
                item: function({ point }) {
                    const country = countries[point.country_id]
                    return `<div>${getTitle(point)}, ${getTitle(country)}</div>`;
                },
                option: function({ point }) {
                    const country = countries[point.country_id]
                    return `<div class="option">${getTitle(point)}, ${getTitle(country)}</div>`;
                },
            },
            onBlur: function() {
                let timeout;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    $notFound.addClass('hidden');
                }, 300);
            },
            onType: function(text) {
                $notFound.toggleClass('hidden', !!this.currentResults.items.length);
            },
            onChange: function(value) {
                if (this.disableOnChange) return;
                $routesFormSubmitBtn.prop('disabled', false);
                const idValue = +value;
                const selectedOption = options.find((option) => option.value === idValue);

                if (selectedOption) {
                    const availableIds = {
                        ...findAvailablePointsFrom(routes, selectedOption.point.id),
                        ...findAvailablePointsFrom(backRoutes, selectedOption.point.id),
                    };
                    const filteredOptions = options.filter(({ point }) => (
                        availableIds[point.id] && !point.isTransitPoint
                    ));

                    switch($select.data('route-direction')) {
                        case 'from':
                            routeData.from = selectedOption;
                            setOptions($selectTo, filteredOptions);
                            break;
                        case 'to':
                            routeData.to = selectedOption;
                            setOptions($selectFrom, filteredOptions);
                            break;
                        default: 
                            return;
                    }
                } else {
                    switch($select.data('route-direction')) {
                        case 'from':
                            routeData.from = 0;
                            setOptions($selectTo, options);
                            break;
                        case 'to':
                            routeData.to = 0;
                            setOptions($selectFrom, options);
                            break;
                        default: 
                            return;
                    }
                    console.log('NOT SELECTED');
                }
            }
        });
    };

    function setOptions($select, options) {
        const { selectize } = $select.get(0);
        const val = selectize.getValue();
        selectize.clearOptions(true);
        options.forEach((option) => {
            selectize.addOption(option);
        });
        selectize.disableOnChange = true;
        selectize.setValue(val);
        selectize.disableOnChange = false;
    };

    function clearRoutesList() {
        $routeResultsList.empty();
    };

    function showErrorHint() {
        let timeout;
        clearTimeout(timeout);
        $routemapErrorHint.fadeIn();
        $routemapErrorHint.removeClass('hidden');
        timeout = setTimeout(() => {
            $routemapErrorHint.fadeOut();
        }, 3000);
    };

    const initVivus = () => {
        const $lines = $('.red-line');
        const $circles = $('.red-line-circle');
        const speed = 1;

        const vivuses = animated.map((itemId) => {
            return new Vivus(itemId, {
                start: 'manual',

            }, (myVivus) => {
                if (myVivus.getStatus() === 'end') {
                    myVivus.reset().play(speed);
                }
            });
        });

        return {
            start: () => {
                vivuses.forEach((vivus) => {
                    $circles.addClass('animate');
                    $lines.addClass('animate');
                    vivus.reset().play(speed);
                });
            },
            stop: () => {
                vivuses.forEach((vivus) => {
                    $circles.removeClass('animate');
                    $lines.removeClass('animate');
                    vivus.reset().stop();
                });
            },
        };
    };

    function validateInputs(inputs) {
        const invalidInputs = [];

        inputs.each(function() {
            const $input = $(this);
            const value = $input.val();
            const name = $input.attr('name');

            if (name === 'name') {
                if (!validateName(value)) {
                    invalidInputs.push($input);
                }
            }

            if (name === 'contact') {
                if (validateEmail(value)) {
                    return true;
                } else if (validatePhone(value)) {
                    return true;
                } else {
                    invalidInputs.push($input);
                }
            }
        });

        if (invalidInputs.length) {
            invalidInputs.forEach((input) => {
                input.libInput('showError');
                input.libInput('showHint');
            });
            return false;
        } else {
            return true;
        }
    };
    
    function initPanzoom($panzoomElem) {
        const $elem = $panzoomElem.get(0);
        panzoomMap = Panzoom($elem, {
            contain: 'outside',
            cursor: 'grab',
            maxScale: 1,
            animate: true
        });

        $elem.parentElement.addEventListener('wheel', panzoomMap.zoomWithWheel);
    };

    function loadSvgRoute(fromPoint, toPoint, success, error) {
        const svgRoutePath = `routesSvg/routeid-${fromPoint.id}/${fromPoint.id}-${toPoint.id}.svg`;
        const svgRoutePathReverse = `routesSvg/routeid-${toPoint.id}/${toPoint.id}-${fromPoint.id}.svg`;

        $.ajax({
            type: 'GET',
            url: `${__webpack_public_path__}${svgRoutePath}`,
            dataType: 'text',
            success: (data) => {
                success(data, `routeid-${fromPoint.id}-${toPoint.id}`, false);
            },
            error: () => {
                $.ajax({
                    type: 'GET',
                    url: `${__webpack_public_path__}${svgRoutePathReverse}`,
                    dataType: 'text',
                    success: (data) => {
                        success(data, `routeid-${toPoint.id}-${fromPoint.id}`, true);
                    },
                    error,
                });
            }
        });
    };
});