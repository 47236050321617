function validateEmail(email) {
    const emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

    if(email.length && emailReg.test(email)) {
        return true;
    } else {
        return false;
    }
}

function validatePhone(phone) {
    const phoneReg = /^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/;
    const formattedPhone = phone.replace(/\D/g,'');

    if(formattedPhone.length && phoneReg.test(formattedPhone)) {
        return true;
    } else {
        return false;
    }
}

function validateName(name) {
    if(name.length > 2) {
        return true;
    } else {
        return false;
    }
}

function validateMessage(message) {
    if(message.length > 2) {
        return true;
    } else {
        return false;
    }
}

export { validateEmail, validatePhone, validateName, validateMessage };