import './gallery.scss';

$('.js-gallery').each(function() {
    const $item = $(this).find('.js-gallery-item');
    const $showBtn = $(this).find('.js-show-btn');
    const $itemCount = $showBtn.find('.js-items-count');
    const hiddenItems = [];
    let count = 0;

    $item.each(function(key) {
        if (key > 6) {
            $(this).addClass('hidden');
            hiddenItems.push($(this));
            count++;
        }
    });

    $itemCount.text('+' + count);

    $showBtn.on('click', () => {
        hiddenItems.forEach((item) => {
            item.removeClass('hidden');
        });
        $showBtn.closest('.gallery__item').addClass('hidden');
    });
});