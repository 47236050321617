import './select.scss';


$(function() {
    $('.js-select').each(function() {
        $(this).selectize({
            closeAfterSelect: true,
            hideSelected: true,
            onFocus: function() {
                const input = 'selectize-input input',
                    wrapper = 'selectize-input';
                $('.' + input).attr('readonly', true);
                $('.' + input + ', .' + wrapper).css('cursor', 'pointer');
            }
        });
    });
});
