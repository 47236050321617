import './upload-file.scss';

export const uploadFile = ({
    element = '.js-upload-file',
    context,
} = {}) => {
    $(element, context).each(function() {
        const $uploadFile = $(this);
        const $input = $uploadFile.find('.js-upload-input');
        $input.on('change', changeInputFile);

        function changeInputFile(e) {
            const fileName = e.target.files[0]?.name;
            $uploadFile.find('[data-file-name]').text(fileName);
            $uploadFile.removeClass('empty');
            $uploadFile.addClass('full');
            $uploadFile.find('[data-file-delete]').on('click', deleteFile);
        };

        function deleteFile(e) {
            e.preventDefault();
            $uploadFile.addClass('empty');
            $uploadFile.removeClass('full');
            $uploadFile.find('[data-file-name]').text = '';
            $input.value = '';
        };
    });
};

uploadFile();